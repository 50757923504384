import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/user/userSlice";
import uploadReducer from "../features/upload/uploadSlice";
import productReducer from "../features/product/productSlice";


export const store = configureStore({
  reducer: {
    auth:authReducer,
    upload: uploadReducer,
    product: productReducer,

  },
});