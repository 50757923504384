import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MoonLoader } from "react-spinners";
import Dropzone from "react-dropzone";
import * as yup from "yup";
import { useFormik } from "formik";
import tyui from "../images/tyui.png";

import {
  delImg,
  uploadImg2,
  uploadImg3,
  uploadImg4,
} from "../features/upload/uploadSlice";
import { updateAUser, updateAUser2 } from "../features/user/userSlice";
import { Link, useNavigate } from "react-router-dom";

const Welcome = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      {loading ? (
        <div className="loadingpage">
          <MoonLoader color="#d7282f" />
        </div>
      ) : (
        <div className="p-m hol-bg">
          <div>
            <div className="top-wel mt-5 container">
              <div className="row">
                <div className="col-lg-4 col-sm-10 col-12 mx-auto">
                  <div className="wel-text mt-5">
                    <h1>Welcome!</h1>
                    <p>Let’s get your account ready.</p>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <div className="d-flex did">
                        <div className="round2">
                          <p>1</p>
                        </div>
                        <div className="active">1</div>
                        <div className="round2">
                          <p className="">2</p>
                        </div>
                        <div className="active"></div>
                        <div className="round2">
                          <p className="">3</p>
                        </div>
                        <div className="active"></div>
                        <div className="round">
                          <p className="act">4</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-7 mx-auto">
                    
                  <div className=" mt-5 main-box">
                  <img className="wel-img" src={tyui} alt=""/>
                    <div className="boxx-top2">
                      <h3>Create your profile</h3>
                      <p>
                        You’re ready to start creating your shiny new Tryst.link
                        profile! If this is your first time creating an advert
                        or you’re new to a site like Tryst.link, check out these
                        helpful articles we’ve put together.
                      </p>
                      
                    </div>
                    <div className="mt-5">
                      <div className="row">
                        <div className="col-11 col-lg-6 mx-auto">
                        <a href="https://app.tryst.link/log_in" className="button-btn3">Create my profile</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Welcome;
