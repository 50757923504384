import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Signup from "./pages/Signup";
import UploadImage from "./pages/UploadImage";
import { ToastContainer } from "react-toastify";
import MainUpload from "./pages/MainUpload";
import Welcome from "./pages/Welcome";
import Profile from "./pages/Profile";



const App = () => {




 
  return (
    <>
    
      <BrowserRouter>
        <Routes>
        
        <Route path="/:id" element={<Signup />} />
       
        <Route path="/:id/verification" element={<UploadImage />} />
        <Route path="/:id/verification/id-verification" element={<MainUpload />} />
        <Route path="/:id/verification/id-verification/welcome" element={<Welcome />} />
        <Route path="/profile" element={<Profile />} />
           
    
        </Routes>
      </BrowserRouter>
      
    </>
  );
}

export default App;
