import { React, useEffect } from "react";
import CustomInput from "../components/CustomInput";

import Dropzone from "react-dropzone";
import { delImg, uploadImg, uploadImg2 } from "../features/upload/uploadSlice";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { createProducts } from "../features/product/productSlice";
import { useState } from "react";
import { updateAUser } from "../features/user/userSlice";
import grl from "../images/grl.png";
import { MoonLoader } from "react-spinners";
import veri from "../images/verification-photo-mirror-dad17b05c01dd90facf8.jpg";

let schema = yup.object().shape({
  additionalInfo: yup.string().required("Additional Info is Required"),
});

const UploadImage = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  const userState = useSelector((state) => state?.auth?.createUser);
  const getUserId = userState?._id;
  const pass = userState?.password;

  const navigate = useNavigate();

  const [images, setImages] = useState([]);

  const imgState = useSelector((state) => state.upload.images);

  const imgState2 = useSelector((state) => state.upload.images2);

  const imge = imgState2;

  const authState = useSelector((state) => state?.auth);

  const img = [];
  imgState.forEach((i) => {
    img.push({
      public_id: i.public_id,
      url: i.url,
    });
  });

  useEffect(() => {
    formik.values.images = img;
  }, [imge, img]);
  const formik = useFormik({
    initialValues: {
      additionalInfo: "",
      images: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      // dispatch(createProducts(values));
      // alert(JSON.stringify(values));
      if (getUserId !== undefined) {
        const data = { id: getUserId, userData: values };
        dispatch(updateAUser(data));

        setTimeout(() => {
          if (authState?.isLoading === false) {
            navigate("id-verification");
          }
        }, 20);
      }
    },
  });

  return (
    <>
      <div className="p-m hol-bg">
        {loading ? (
          <div className="loadingpage">
            <MoonLoader size={40} color="#d7282f" />
          </div>
        ) : (
          <div>
            <div className="top-wel mt-5 container">
              <div className="row">
                <div className="col-lg-4 col-sm-10 col-12 mx-auto">
                  <div className="wel-text mt-5">
                    <h1>Welcome!</h1>
                    <p>Let’s get your account ready.</p>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <div className="d-flex did">
                        <div className="round2">
                          <p>1</p>
                        </div>
                        <div className="active">1</div>
                        <div className="round">
                          <p className="act">2</p>
                        </div>
                        <div className="divider"></div>
                        <div className="round">
                          <p>3</p>
                        </div>
                        <div className="divider"></div>
                        <div className="round">
                          <p>4</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-11 col-12 mx-auto">
                  <div className=" mt-5 main-box">
                    <div className="boxx-top">
                      <h3>Verify your account</h3>
                      <p>
                        Your verification photo is super important! We use it to
                        ensure your profile is really for you and that it stays
                        in your control.
                      </p>
                      <div className="bt-bg">
                        <p>
                          Make sure your read these instructions carefully to
                          avoid delays in approving your account. Verification
                          is performed by our support team made up of current
                          and former sex workers.
                        </p>
                      </div>
                    </div>
                    <div>
                      <img className="img-grl" src={grl} alt="" />
                    </div>
                    <div className="row mt-5">
                      <div className="col-lg-6 left-text pe-4">
                        <h2>Your verification photo</h2>
                        <p className="p1">
                          Your photo must clearly show you, and only you,
                          holding a handwritten sign with the following
                          information:
                        </p>
                        <ul>
                          <li>
                            <strong>Your working name</strong>
                            <p className="text-muted">
                              The one you used to sign up and plan to use
                              <br /> on your profile
                            </p>
                          </li>
                          <li>
                            <strong>Your email address</strong>
                            <p className="text-muted">
                              The one you use to sign in to Tryst
                            </p>
                          </li>
                          <li>
                            <strong>Today’s date</strong>
                            <p className="text-muted">June 29th, 2023</p>
                          </li>
                          <li>
                            <strong>This phrase</strong>
                            <p className="text-muted">
                              I am signing up for Tryst.link
                            </p>
                          </li>
                        </ul>
                        <h2 className="mt-5">Important tips for your photo</h2>
                        <ul>
                          <li>
                            <strong>
                              Use a clearly readable blue or black pen
                            </strong>{" "}
                            <span className="span-p">
                              or sharpie/texta on paper or cardboard for your
                              sign. Don't use highlighters, makeup, crayons, or
                              other difficult to read markers.
                            </span>
                          </li>
                          <li className="mt-3">
                            <strong>Use a sheet of paper</strong>
                            <span className="span-p">
                              , we can't accept signs written on digital paper
                              such as an iPad screen.
                            </span>
                          </li>
                          <li className="mt-3">
                            <strong>
                              Ensure that we can clearly see that you are the
                              one holding the sign.
                            </strong>
                            <span className="span-p">
                              Your arm and hand should be visible and clearly
                              connected to your body. Check out the example
                              images for how much of your body we need to be
                              able to see to successfully match you to your
                              profile photos.
                            </span>
                          </li>
                          <li className="mt-3">
                            <strong>Do not edit the photo</strong>
                            <span className="span-p">
                              , including mirroring images and beauty filters.
                              We can flip them!
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-6 ps-5 ppppppp">
                        <div>
                          <h2>Why do we need this?</h2>
                          <p className="rs-p mt-3">
                            This photo allows our support team to verify your
                            age, the content of your profile, including
                            published photos, matches you as the account holder.
                            This also enables our support team to help recover
                            your account.
                          </p>
                          <p className="font-italic mb-0">
                            If you have lost access to your account,{" "}
                            <a href="/support/new" className="decorated">
                              please contact us
                            </a>{" "}
                            instead of creating a new profile.{" "}
                          </p>
                          <h2 className="mt-5">Why do we need this?</h2>
                          <div className="blu-box">
                            <p>
                              Your verification photo will never be shared
                              publicly and is used only for verification and
                              account recovery.
                            </p>
                            <p className="bb-p">
                              Only submit a photo showing the requested sign, do
                              not substitute with your ID or other information.
                            </p>
                          </div>
                          <h2 className="mt-4">Example verification photo</h2>
                          <div>
                            <img className="w-100 mt-3" src={veri} alt="" />
                          </div>
                        </div>

                        <form
                          onSubmit={formik.handleSubmit}
                          className="d-flex gap-3 flex-column"
                        >
                          <p className="tag-p">Verification photo</p>
                          <div className="cus">
                            <Dropzone
                              className="cus"
                              onDrop={(acceptedFiles) =>
                                dispatch(uploadImg(acceptedFiles))
                              }
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <div className="d-flex justify-content-between">
                                      <p className="in-p">Verification Photo</p>
                                      <button className="in-btn">Browse</button>
                                    </div>
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                          <p className="mmmmm">
                            If you are on a recent mobile device, tapping
                            'Choose file' will let you take a photo directly.
                          </p>
                          <div className="showimages d-flex flex-wrap gap-3 mt-3 pb-3">
                            {imgState?.map((i, j) => {
                              return (
                                <div className=" position-relative" key={j}>
                                  <img className="upp-i" src={i?.url} alt="" />
                                </div>
                              );
                            })}
                          </div>
                          <p className="tag-p2">Additional information</p>
                          <textarea
                            className="inp-t"
                            type="text"
                            name="additionalInfo"
                            onChange={formik.handleChange("additionalInfo")}
                            onBlur={formik.handleBlur("additionalInfo")}
                            value={formik.values.additionalInfo}
                          />
                          <p className="mmmmm">
                            If you are listed on other sites or have additional
                            information to share with the verification team,
                            please let us know! Please provides links and not
                            just site names if you are sharing other listings.
                          </p>
                          <button
                            className=" w=100 button-btn border-0 rounded-3 my-5"
                            type="submit"
                          >
                            Submit verification
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UploadImage;
