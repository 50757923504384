import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";

const uploadImg = async (data) => {
  console.log(data);
  const response = await axios.post(`${base_url}upload/`, data);
  return response.data;
};



const uploadImg2 = async (data) => {
  console.log(data);
  const response = await axios.post(`${base_url}upload/img`, data);
  return response.data;
};
const uploadImg3 = async (data) => {
  console.log(data);
  const response = await axios.post(`${base_url}upload/img1`, data);
  return response.data;
};
const uploadImg4 = async (data) => {
  console.log(data);
  const response = await axios.post(`${base_url}upload/img2`, data);
  return response.data;
};






const deleteImg = async (id) => {
  const response = await axios.delete(
    `${base_url}upload/delete-img/${id}`,

    
  );
  return response.data;
};

const uploadService = {
  uploadImg,
  uploadImg2,
  uploadImg3,
  uploadImg4,
  deleteImg,
};

export default uploadService;