import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MoonLoader } from "react-spinners";
import Dropzone from "react-dropzone";
import * as yup from "yup";
import { useFormik } from "formik";
import veri1 from "../images/2nd-veri1.jpg";
import veri2 from "../images/2nd-veri2.jpg";
import veri3 from "../images/2nd-veri3.jpg";

import {
  delImg,
  uploadImg2,
  uploadImg3,
  uploadImg4,
} from "../features/upload/uploadSlice";
import { updateAUser, updateAUser2 } from "../features/user/userSlice";
import { useNavigate } from "react-router-dom";

let schema = yup.object().shape({
  name: yup.string().required("Name Info is Required"),
  dob: yup.string().required("Name Info is Required"),
  document: yup.string().required("Name Info is Required"),
  issuer: yup.string().required("Name Info is Required"),
  expiry: yup.string().required("Name Info is Required"),
  assist: yup.string().required("Name Info is Required"),
});

const MainUpload = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authState = useSelector((state) => state?.auth);

  const userState = useSelector((state) => state?.auth?.createUser);
  const getUserId = userState?._id;

  const imgState2 = useSelector((state) => state.upload.images2);
  const imge = imgState2;
  const imgState3 = useSelector((state) => state.upload.images3);
  const imgee = imgState3;
  const imgState4 = useSelector((state) => state.upload.images4);
  const imgeee = imgState4;
  console.log(getUserId);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  // const imge = [];
  // imgState2.forEach((i) => {
  //   imge.push({
  //     public_id: i.public_id,
  //     url: i.url,
  //   });
  // });

  useEffect(() => {
    formik.values.images2 = imge;
    formik.values.images3 = imgee;
    formik.values.images4 = imgeee;
  }, [imge, imgee, imgeee]);

  const formik = useFormik({
    initialValues: {
      name: "",
      dob: "",
      document: "",
      issuer: "",
      expiry: "",
      assist: "",
      images2: "",
      images3: "",
      images4: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values));
      // console.log(values);
      if (getUserId !== undefined) {
        const data = { id: getUserId, userData: values };
        dispatch(updateAUser2(data));
        // alert(JSON.stringify(data));
        // console.log(data);

        setTimeout(() => {
         
          if (authState.isSuccess) {
            navigate("welcome");
            
          }
        }, 50);
      }
    },
  });

  return (
    <div className="p-m hol-bg">
      {loading ? (
        <div className="loadingpage">
          <MoonLoader  size={40} color="#d7282f" />
        </div>
      ) : (
        <div>
          <div className="top-wel mt-5 container">
            <div className="row">
              <div className="col-lg-4 col-sm-10 col-12 mx-auto">
                <div className="wel-text mt-5">
                  <h1>Welcome!</h1>
                  <p>Let’s get your account ready.</p>
                </div>
                <div className="row">
                  <div className="col-3">
                    <div className="d-flex did">
                      <div className="round2">
                        <p>1</p>
                      </div>
                      <div className="active">1</div>
                      <div className="round2">
                        <p className="">2</p>
                      </div>
                      <div className="active"></div>
                      <div className="round">
                        <p className="act">3</p>
                      </div>
                      <div className="divider"></div>
                      <div className="round">
                        <p>4</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-11 mx-auto">
                <div className=" mt-5 main-box">
                  <div className="boxx-top">
                    <h3>ID verification</h3>
                    <p className="tooooooppp">
                      Tryst.link is a platform for sex workers, by sex workers.
                      We do not take asking for your personal information
                      lightly but, due to increasing compliance requirements, we
                      require a valid form of government-issued photo
                      identification to verify your age, prior to creating a
                      profile.
                    </p>
                    <div className="re-d">
                      <p>Learn more about why we need to do this</p>
                    </div>
                    <div className="yollo">
                      <p>
                        Your ID will be reviewed by a member of our team; this
                        is not outsourced to a computer or third-party service.
                      </p>
                    </div>
                  </div>
                  <div className="mt-5">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-lg-5">
                          <h2 className="mt-4">Upload your ID photos</h2>
                          <div>
                            <img
                              className="w-100 mt-3 img-bor"
                              src={veri1}
                              alt=""
                            />
                          </div>
                          <p color="#5d738d" className="mt-3">
                            Make sure we can clearly see that you are the one
                            holding your ID, like in this example.
                          </p>
                        </div>
                        <div className="col-lg-6 ms-5 input-cal">
                          <p className="veri-p">
                            <strong>Image 1: </strong> A selfie of you holding
                            your ID
                          </p>
                          <p className="veri-p">
                            Take a selfie that clearly shows your face and you
                            holding the photo side of your ID facing the camera.
                            Make sure the photo is clear and that we can clearly
                            see you're holding your ID document, but don’t worry
                            about the text being readable in this photo. If you
                            wear glasses, make sure you take them off for this
                            photo.
                          </p>
                          <div className="cus">
                            <Dropzone
                              className="cus"
                              onDrop={(acceptedFiles) =>
                                dispatch(uploadImg2(acceptedFiles))
                              }
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <div className="d-flex justify-content-between">
                                      <p className="in-p2">
                                        A selfie of you holding your ID
                                      </p>
                                      <button className="in-btn">Browse</button>
                                    </div>
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                          <div className="showimages d-flex flex-wrap gap-3 mt-3">
                            {imgState2?.map((i, j) => {
                              return (
                                <div className=" position-relative" key={j}>
                                  <img
                                    src={i?.url}
                                    alt=""
                                    width={150}
                                    height={150}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-5">
                          <div className="mt-5">
                            <img
                              className="w-100 mt-3 img-bor"
                              src={veri2}
                              alt=""
                            />
                          </div>
                          <p color="#5d738d" className="mt-3">
                            Get nice and close and ensure everything is
                            readable. Make sure we can see all the edges of your
                            ID, like in this example.
                          </p>
                        </div>
                        <div className="col-lg-6 ms-5 input-cal">
                          <p className="veri-p">
                            <strong>Image 2: </strong> A photo of the front of
                            your ID
                          </p>
                          <p className="veri-p">
                            Take a photo of the front of your ID (the one
                            showing your photo), ensuring we can read all the
                            labels and text.
                          </p>
                          <p className="veri-p">
                            <i>Using a passport? </i> This is the photo page
                            inside the document.
                          </p>
                          <div className="cus">
                            <Dropzone
                              className="cus"
                              onDrop={(acceptedFiles) =>
                                dispatch(uploadImg3(acceptedFiles))
                              }
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <div className="d-flex justify-content-between">
                                      <p className="in-p2">
                                        A photo of the front of your ID
                                      </p>
                                      <button className="in-btn">Browse</button>
                                    </div>
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                          <div className="showimages d-flex flex-wrap gap-3 mt-3">
                            {imgState3?.map((i, j) => {
                              return (
                                <div className=" position-relative" key={j}>
                                  <img
                                    src={i?.url}
                                    alt=""
                                    width={150}
                                    height={150}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-5">
                          <div className="mt-5">
                            <img
                              className="w-100 mt-3  img-bor"
                              src={veri3}
                              alt=""
                            />
                          </div>
                          <p color="#5d738d" className="mt-3">
                            Get nice and close and ensure everything is
                            readable. Make sure we can see all the edges of your
                            ID, like in this example.
                          </p>
                        </div>
                        <div className="col-lg-6 ms-5 input-cal">
                          <p className="veri-p">
                            <strong>Image 3: </strong> A photo of the reverse of
                            your ID
                          </p>
                          <p className="veri-p">
                            Take a photo of the back of your ID, ensuring we can
                            read all the labels and text.
                          </p>
                          <p className="veri-p">
                            <i>Using a passport?</i> This photo should be of the
                            cover of the document, including both the front
                            (where the country name is) and back.
                          </p>
                          <div className="cus">
                            <Dropzone
                              className="cus"
                              onDrop={(acceptedFiles) =>
                                dispatch(uploadImg4(acceptedFiles))
                              }
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <div className="d-flex justify-content-between">
                                      <p className="in-p2">
                                        A photo of the reverse of your ID
                                      </p>
                                      <button className="in-btn">Browse</button>
                                    </div>
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                          <div className="showimages d-flex flex-wrap gap-3 mt-3">
                            {imgState4?.map((i, j) => {
                              return (
                                <div className=" position-relative" key={j}>
                                  <img
                                    src={i?.url}
                                    alt=""
                                    width={150}
                                    height={150}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-5"></div>
                        <div className="col-lg-6 ms-5 input-cal">
                          <h2 className="mt-4">Additional information</h2>
                          <p className="inp-lable">Name as shown on ID</p>
                          <input
                            className="input-fuild"
                            type="text"
                            name="name"
                            onChange={formik.handleChange("name")}
                            onBlur={formik.handleBlur("name")}
                            value={formik.values.name}
                          />
                          <p color="#5d738d" className="mt-3">
                            Enter your name exactly as it appears on your ID
                            document, this name will never be connected to your
                            profile and will not be used when the team interact
                            with you.
                          </p>
                          <p className="inp-lable mt-4">Date of birth</p>
                          <input
                            className="input-fuild"
                            type="date"
                            name="dob"
                            onChange={formik.handleChange("dob")}
                            onBlur={formik.handleBlur("dob")}
                            value={formik.values.dob}
                          />
                          <p color="#5d738d" className="mt-3">
                            Ensure this matches what is shown on your ID. Use
                            the date picker to avoid any errors that may force
                            us to reject your submission.
                          </p>
                          <p className="inp-lable mt-4">Type of document</p>
                          <select
                            name="document"
                            onChange={formik.handleChange("document")}
                            onBlur={formik.handleBlur("document")}
                            value={formik.values.document}
                            className="input-fuild py-3 mb-3"
                            id=""
                          >
                            <option value="">Select</option>
                            <option value="Passport">Passport</option>
                            <option value="Driver licence">
                              Driver licence
                            </option>
                            <option value="Citizenship card">
                              Citizenship card
                            </option>
                            <option value="Proof-of-age card">
                              Proof-of-age card
                            </option>
                            <option value="Other (specify)">
                              Other (specify)
                            </option>
                          </select>
                          <p color="#5d738d" className="mt-3">
                            Select the type of document you are submitting to
                            ensure we can review it correctly. If it isn't in
                            the list, select other.
                          </p>
                          <p className="inp-lable mt-4">Issuer</p>
                          <input
                            className="input-fuild"
                            type="text"
                            name="issuer"
                            onChange={formik.handleChange("issuer")}
                            onBlur={formik.handleBlur("issuer")}
                            value={formik.values.issuer}
                          />
                          <p color="#5d738d" className="mt-3">
                            The name or description of the issuing body for your
                            ID. Exact matches aren't required, just ensure we
                            can identify them: e.g., "Michigan DMV", "German
                            Passport Office", "VicRoads".
                          </p>
                          <p className="inp-lable mt-4">
                            Expiry date of identification
                          </p>
                          <input
                            className="input-fuild"
                            type="date"
                            name="expiry"
                            onChange={formik.handleChange("expiry")}
                            onBlur={formik.handleBlur("expiry")}
                            value={formik.values.expiry}
                          />
                          <p color="#5d738d" className="mt-3">
                            This must the same as the expiry date as is
                            displayed on your ID.
                          </p>

                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                            >
                              I have a medical exemption that affects my photos
                              (if applicable)
                            </label>
                            <p className="">
                              You confirm that you have a valid medical
                              exemption that's approved by the ID issuer to wear
                              optical glasses, facial posthetics, eye mask,
                              and/or medical dressings.
                            </p>
                          </div>

                          <p className="inp-lable mt-4">
                            Is there anything you can add to better assist the
                            team in verifying you?
                          </p>
                          <textarea
                            className="inp-t w-100"
                            type="text"
                            name="assist"
                            onChange={formik.handleChange("assist")}
                            onBlur={formik.handleBlur("assist")}
                            value={formik.values.assist}
                          />
                          <p color="#5d738d" className="mt-3">
                            If you need to share any other information with the
                            verification team, let us know here! If you have
                            declared a medical exemption, helping us understand
                            how this affects the photos you have taken will be
                            helpful.
                          </p>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                            >
                              I consent to my ID being verified
                            </label>
                            <p className="">
                              Your ID will be reviewed by a member of our team;
                              this is not outsourced to a computer or
                              third-party service.
                            </p>
                          </div>
                          <button
                            className=" w-100 button-btn2 border-0 rounded-3"
                            type="submit"
                          >
                            Submit ID
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainUpload;
