import React from "react";
import { useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";

const Profile = () => {
    const userState = useSelector((state) => state?.auth?.createUser);
    const getUserId = userState?._id;
    console.log(getUserId);

  return (
    <>
     <h1>Congratulations</h1>
    </>
  );
};

export default Profile;
