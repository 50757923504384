import axios from "axios";
import { base_url } from "../../utils/baseUrl";


const registerUser = async(userData) => {
    const response = await axios.post(`${base_url}user/register`, userData);
    if(response.data){
        return response.data;
    }
};


const loginUser = async(userData) => {
    const response = await axios.post(`${base_url}user/login`, userData);
    if(response.data){
        return response.data;
    }
};



const getUsers = async () => {
    const response = await axios.get(`${base_url}user/all-users`);
  
    return response.data;
  };
  
const getAUsers = async (id) => {
    const response = await axios.get(`${base_url}user/${id}`);
    if(response.data){
        return response.data;
    }
  };


  const updateUser = async (user) => {
    console.log(user);
    const response = await axios.put(
      `${base_url}user/update/${user.id}`,
      
      { 
        id: user.id,
        additionalInfo: user.userData.additionalInfo,
        images: user.userData.images,
      },
    );
  
    return response.data;
  };


  const updateUser2 = async (user) => {
    console.log(user);
    const response = await axios.put(
      `${base_url}user/update2/${user.id}`,
      
      { 
        id: user.id,
        name: user.userData.name,
        dob: user.userData.dob,
        document: user.userData.document,
        issuer: user.userData.issuer,
        expiry: user.userData.expiry,
        assist: user.userData.assist,
        images2: user.userData.images2,
        images3: user.userData.images3,
        images4: user.userData.images4,
      },
    );
  
    return response.data;
  };




  













export const authService = {
    registerUser,
    loginUser,
    getUsers,
    getAUsers,
    updateUser,
    updateUser2,
}