import React from "react";
import logo from "../images/logo.PNG";
import footerlogo from "../images/footer-logo.svg";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-2">
            <div className="fot-flex">
              <img className="footer-log" src={logo} alt="" />
              <img className="footer-logo" src={footerlogo} alt="" />
            </div>
          </div>
          <div className="col-lg-2"></div>
          <div className="col-lg-2 col-6">
            <div className="footer-link mt-5">
              <div>
                <a href="">Home</a>
              </div>
              <div>
                <a href="">Memberships & Pricing</a>
              </div>
              <div>
                <a href="">Sign Up</a>
              </div>
              <div>
                <a href="">Login</a>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="footer-link mt-5">
              <div>
                <a href="">Locations</a>
              </div>
              <div>
                <a href="">All Escorts</a>
              </div>
              <div>
                <a href="">Backpage Alternatives</a>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="footer-link mt-5">
              <div>
                <a href="">Blog</a>
              </div>
              <div>
                <a href="">TLC Donation Matching</a>
              </div>
              <div>
                <a href="">Resources</a>
              </div>
              <div>
                <a href="">#AcceptanceMatters</a>
              </div>
              <div>
                <a href="">Escort Terms</a>
              </div>
              <div>
                <a href="">Tryst.link FAQ</a>
              </div>
              <div>
                <a href="">Sex Work FAQ</a>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="footer-link mt-5">
              <div>
                <a href="">Help / Support</a>
              </div>
              <div>
                <a href="">About</a>
              </div>
              <div>
                <a href="">Social</a>
              </div>
              <div>
                <a href="">Terms</a>
              </div>
              <div>
                <a href="">Privacy</a>
              </div>
              <div>
                <a href="">Legal Notices</a>
              </div>
              <div>
                <a href="">Anti-Exploitation Policy</a>
              </div>
            </div>
          </div>
        </div>
        <div className="copyW">
          <p>
            <small class="text-white-50 ">© 2018–2023, Assembly Four</small>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
